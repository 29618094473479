<template>
  <div class="choose-medicine">
    <el-dialog title="选择药品" :visible.sync="visible" :center='true' >
      <!-- <el-input placeholder="药品名称/简称关键字查询" v-model="key" style="width: 50%">
          <el-button slot="append" @click="getDataList" icon="el-icon-search"></el-button>
        </el-input> -->
      <!-- <el-table class='mt20' :data="medicineList" :border='true' v-loading="dataListLoading" highlight-current-row @current-change="handleCurrentChange">
        <el-table-column prop='drugsSimpleName' label='药品简称'></el-table-column>
        <el-table-column prop='drugsUseTypeName' label='用法'></el-table-column>
        <el-table-column prop='drugsBaseDose' label='用量'></el-table-column>
        <el-table-column prop='drugsUseUnitName' label='单位'></el-table-column>
        <el-table-column prop='hz' label='对应频率' width=200></el-table-column>
        <el-table-column prop='drugsProductFirm' label='厂商'></el-table-column>
      </el-table> -->
      <el-form label-width="300px">
        <el-form-item label="药品名称" prop='drugsManageId' :rules="dataRules"> 
              <el-select
              placeholder="请选择"
              v-model="form.drugsManage"
              clearable
              style="width: 40%"
               filterable
               @change='drugChange'
               value-key='id'
              >
                  <el-option
                  v-for="(item,index) in medicineList"
                  :key="index"
                  :value="item"
                  :label="item.drugsSimpleName"
                  ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用法" required>
		<el-select
		  v-model="form.drugsUseType"
		  placeholder="请选择"
		  style="width: 40%"
      
		>
		  <el-option
		    v-for="item in yfTypeArr"
		    :key="item.value"
		    :label="item.name"
		    :value="item.value"
		  ></el-option>
		</el-select>
		</el-form-item>
		<el-form-item label="单位" required>
		<el-select
		  v-model="form.drugsUseUnit"
		  placeholder="请选择活动类型"
		  style="width: 40%"
		>
		  <el-option
		    v-for="item in dwTypeArr"
		    :key="item.value"
		    :label="item.name"
		    :value="item.value"
		  ></el-option>
		</el-select>
		</el-form-item>
		<el-form-item label="基础剂量" required>
		  <el-input
		    style="width: 40%"
		    placeholder="请输入基础剂量"
		    v-model="form.drugsBaseDose"
		  ></el-input>
		</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:"chooseMedicine",
  // props:{
  //   visible:{
  //     type:Boolean,
  //     default:false,
  //   }
  // },
  data(){
    return{
      // visible:'',
      visible:false,
      currentRow:'',
      dataListLoading:true,
      medicineList:[],//药品列表
      key:'',//关键词
      form:{
        drugsUseType:'',
        drugsUseUnit:'',
        drugsBaseDose:'',
        drugsManage:'',
        drugsItem:''
      },
      dataRules:{
        activityName: [{ required: true, message: "活动名不能为空", trigger: "blur" }],
        activityType: [{ required: true, message: '请选择活动类型', trigger: 'change' }],
        executionTime: [{ required: true, message: "执行时间不能为空", trigger: "blur" }],
        orderNum: [{ required: true, message: "活动排序不能为空", trigger: "blur" }],
        activityStart: [{ required: true, message: "时间不能为空", trigger: "blur" }],
      },
      dwTypeArr:[],
      yfTypeArr:[],

    }
  },
  created() {
    // console.log(this.visible,'created');
  },
  mounted() {
    // this.getTypeArr()
    // this.init()
  },
  methods: {
  //   getTypeArr() {
  //   this.$http({
  //     url: this.$http.adornUrl("/dict/list?type=用法"),
  //     method: "get",
  //   }).then(({ data }) => {
  //     this.yfTypeArr = data.data;
  //   });
	// this.$http({
	//   url: this.$http.adornUrl("/dict/list?type=单位"),
	//   method: "get",
	// }).then(({ data }) => {
	//   this.dwTypeArr = data.data;
	// });
	// this.$http({
	//   url: this.$http.adornUrl("/dict/list?type=频率"),
	//   method: "get",
	// }).then(({ data }) => {
	//   this.plTypeArr = data.data;
	// });
    // },
    init(){
      // console.log('initttttttttttttttttttt');
      this.visible = true
      this.dwTypeArr =JSON.parse(sessionStorage.getItem('dwTypeArr'))
      this.yfTypeArr =JSON.parse(sessionStorage.getItem('yfTypeArr'))
    this.getDataList()
    },
    // test(){
    //   console.log('11111111111111111111111');
    // },
    drugChange(val){
      console.log(val);
      // let item = this.medicineList.filter(v=>v.id==val)[0]
      // console.log(item);
      this.form.drugsUseType = val.drugsUseType
      this.form.drugsUseUnit = Number(val.drugsUseUnit) 
      this.form.drugsBaseDose = val.drugsBaseDose
      this.form.drugsSimpleName = val.drugsSimpleName
      this.form.drugsUseUnitName = val.drugsUseUnitName
      this.form.id = val.id
    },
    confirm(){
      // console.log(this.currentRow);
      if(!this.form.drugsManage){
        this.$message.error('请选择药品！')
        return
      }
      // this.form.drugsManage.push(this.form.drugsItem)
      this.$emit('medicine',this.form)
      this.visible= false
      // this.currentRow = ''
      this.key = ''
    },
    handleCurrentChange(val) {
        this.currentRow = val;
    },
    async getDataList(){
      const user = JSON.parse(localStorage.getItem('userInfo'))
      // console.log(user);
      const {data:res} = await this.$httpAes({
        url:this.$httpAes.adornUrl('/drugsManage/list'),
        method:'post',
        data:{
          stringParam1:this.key,
          stringParam2:user.deptPid,
          // deptId:user.deptId
        }
      })
      // console.log(res,'res')
      if(res.status){

        res.data.forEach(v=>{
          v.hz=`每${v.drugsIntervalNum}${v.drugsDefaultRateName}的${v.drugsUseTime}`
        })
        this.medicineList=res.data
        this.dataListLoading=false
      }else{
        this.$message.error('获取列表失败，请重试');
        this.dataListLoading=false
      }    
    },
  },
  
}
</script>