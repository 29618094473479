<template>
  <div class="circle">
    <div class="left">
      <el-button
        type="primary"
        style="width: 100%; margin-bottom: 20px"
        @click="addActivity"
        >新增周期活动</el-button
      >
      <div
        class="card"
        v-for="(card, index) in formList2"
        :key="index"
        v-show="card.leftShow"
        @click.stop="showRight(index)"
      >
        <span class="text">{{ card.activityName }}</span>
        <el-button
          style="float: right; padding: 3px 0; color: #f56c6c"
          type="text"
          @click.stop="delActivity(card)"
          >删除</el-button
        >
      </div>
    </div>
    <div class="right">
      <el-form
        v-for="(form, index) in formList"
        :key="index"
        v-show="form.show"
        ref="form"
        :model="form"
        label-width="100px"
        class="form"
        :rules="dataRules"
      >
        <div class="line"></div>
        <div class="content">
          <div class="lie">
            <el-form-item label="活动名" prop="activityName">
              <el-input
                v-model.trim="form.activityName"
                placeholder="请填写活动名"
                class="wd300"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动类型" prop="activityType">
              <el-select
                :disabled="form.flag"
                placeholder="请选择活动类型"
                v-model="form.activityType"
                clearable
                class="wd300"
                @change="changeActivityType(index)"
              >
                <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="lie">
            <el-form-item label="频率" prop="activityRate">
              <el-select
                placeholder="请选择频率"
                v-model="form.activityRate"
                clearable
                class="wd300"
                @change="changeRate(index)"
              >
                <el-option
                  v-for="(item, index) in rateList"
                  :key="index"
                  :value="item.value"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <div>
              <el-form-item label="每" prop="activityFrequency">
                <!-- <el-input v-model='form.activityFrequency' class='wd100' type='number'  oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>&nbsp&nbsp&nbsp
                      {{form.activityRate?rateList[form.activityRate-1].name:''}}开始 -->
                <el-select
                  v-model="form.activityFrequency"
                  placeholder="请选择"
                  @change="changeOptions(index)"
                  clearable
                  class="wd300"
                >
                  <el-option
                    v-for="item in form.everyList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="持续" prop='executionDay'>
                      <el-input class='wd100' type='number' v-model="form.executionDay"  oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>&nbsp&nbsp&nbsp天

                    </el-form-item> -->
            </div>
          </div>
          <div class="lie" v-if="form.activityRate > 1">
            <el-form-item label="第" prop="activityStart">
              <!-- <el-date-picker
                        type="dates"
                        v-model="form.activityStart"
                        placeholder="选择一个或多个日期"
                        format="yyyy-MM-dd" 
                        value-format="yyyy-MM-dd">
                      </el-date-picker> -->
              <el-select
                v-model="form.activityStart"
                multiple
                placeholder="请选择"
                class="wd300"
              >
                <el-option
                  v-for="item in form.options"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              天
            </el-form-item>
          </div>
          <div class="lie">
            <el-form-item label="执行时间" prop="executionTime">
              <el-time-picker
                class="wd300"
                v-model="form.executionTime"
                placeholder="请选择执行时间"
                value-format="HH:mm:ss"
              >
                <!-- type='date' -->
              </el-time-picker>
            </el-form-item>
            <el-form-item label="活动排序" prop="orderNum">
              <el-input
                v-model="form.orderNum"
                type="number"
                class="wd300"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input>
            </el-form-item>
          </div>
          <div v-if="form.activityType == 6" class="lie">
            <el-form-item label="窗口期">
              -
              <el-input
                v-model="form.startDay"
                type="number"
                class="wd100"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input
              >天 &nbsp; ~ &nbsp; +<el-input
                v-model="form.endDay"
                type="number"
                class="wd100"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input
              >天
            </el-form-item>
          </div>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入备注"
              v-model="form.activityRemarks"
            >
            </el-input>
          </el-form-item>
        </div>
        <div class="main-cnt">
          <div class="title">
            <div class="shu"></div>
            <div class="text">主题内容</div>
          </div>
          <div
            class="cnt-input"
            v-if="
              (form.activityType != 1) &
              (form.activityType != 4) &
              (form.activityType != 5) &
              (form.activityType != 6)
            "
          >
            <el-form-item label="活动描述">
              <el-input
                type="textarea"
                placeholder="请填写活动描述"
                v-model="form.activityDescription"
              ></el-input>
            </el-form-item>
          </div>
          <template v-else>
            <div
              class="other-type"
              v-if="!form.flag"
              @click="addMainCnt(form, index)"
            >
              + 新增主题内容
            </div>
            <!-- 如果有内容 -->
            <!-- <template v-else>
                    <template v-if='form.activityType==1'>
                    <el-table :data='form.medicineList' :border='true'> 
                      <el-table-column prop='drugsSimpleName' label='药品简称'></el-table-column>
                      <el-table-column prop='drugsUseTypeName' label='用法'></el-table-column>
                      <el-table-column prop='drugsBaseDose' label='用量'></el-table-column>
                      <el-table-column prop='drugsUseUnitName' label='单位'></el-table-column>
                      <el-table-column label='操作' header-align="center" align="center" fixed="right"> 
                        <template slot-scope="scope">
                          <el-button type="text" size="small" @click='deleteMed(scope.row,index)'>删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    </template>
                    <template v-else-if='form.activityType==4'>
                      <el-table :data='form.missionaryWorks' :border='true'> 
                        <el-table-column prop='workTypeName' label='宣教分类'></el-table-column>
                        <el-table-column prop='workName' label='宣教名称'></el-table-column>
                        <el-table-column label='操作' header-align="center" align="center" > 
                          <template slot-scope="scope">
                            <el-button type="text" size="small" @click='deleteMed(scope.row,index)'>删除</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                    <template v-else-if='form.activityType==5'>
                      <el-table :data='form.indexLibraries' :border='true'> 
                        <el-table-column prop='libraryTypeName' label='随访分类'></el-table-column>
                        <el-table-column prop='libraryName' label='随访名称'></el-table-column>
                        <el-table-column label='操作' header-align="center" align="center" > 
                          <template slot-scope="scope">
                            <el-button type="text" size="small" @click='deleteMed(scope.row,index)'>删除</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </template> -->
            <template v-else>
              <!-- 第一种活动类型 服药的列表 -->
              <template v-if="form.activityType == 1">
                <el-table :data="form.medicineList" :border="true">
                  <el-table-column
                    prop=".drugsSimpleName"
                    label="药品简称"
                  ></el-table-column>
                  <el-table-column
                    prop="drugsUseTypeName"
                    label="用法"
                  ></el-table-column>
                  <el-table-column
                    prop="drugsBaseDose"
                    label="用量"
                  ></el-table-column>
                  <el-table-column
                    prop=".drugsUseUnitName"
                    label="单位"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    header-align="center"
                    align="center"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click="deleteMed(scope.row, index)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <template v-else-if="form.activityType == 4">
                <el-table :data="form.missionaryWorks[0]" :border="true">
                  <!-- <el-table-column prop='workTypeName' label='宣教一级分类'></el-table-column> -->
                  <!-- <el-table-column prop='workTypeSecName' label='宣教二级分类'></el-table-column> -->
                  <el-table-column
                    prop="workName"
                    label="宣教名称"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    header-align="center"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click="deleteMed(scope.row, index)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <template
                v-else-if="form.activityType == 5 || form.activityType == 6"
              >
                <el-table :data="form.indexLibraries" :border="true">
                  <!-- <el-table-column prop='libraryTypeName' label='随访分类'></el-table-column> -->
                  <el-table-column
                    prop="tableName"
                    label="问卷名称"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    header-align="center"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click="deleteMed(scope.row, index, scope.$index)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </template>
            <div
              class="other-type"
              v-if="
                form.flag && (form.activityType == 5 || form.activityType == 6)
              "
              @click="addMainCnt(form, index)"
            >
              + 新增主题内容
            </div>
          </template>
        </div>
        <div class="mt20 mb20" style="text-align: center">
          <el-button @click="cancelActivity(index)">取消</el-button>
          <el-button type="primary" @click="submit(index, 2)" v-points
            >保存</el-button
          >
        </div>
        <choose-medicine
          v-show="form.dialog"
          ref="chooseMed"
          @medicine="getMed($event, index)"
        ></choose-medicine>
        <choose-question
          v-show="form.qsDialog"
          ref="chooseQs"
          @chooseQs="getOther($event, index)"
          :type="form.activityType"
        ></choose-question>
        <!-- <choose-question v-if='chooseQsDialog' ref='`chooseQs${index}`' ></choose-question> -->
      </el-form>
    </div>
  </div>
</template>
<script>
import chooseMedicine from "./chooseMedicine";
import chooseQuestion from "./chooseQuestion";
import Qs from "qs";
export default {
  name: "circle",
  components: {
    chooseMedicine,
    chooseQuestion,
  },
  props: ["entityId", "programInfoId", "typeList", "circleList", "edit"],
  data() {
    return {
      diseases: "", //病种value
      // programInfoId:this.programInfoId,//方案id
      // typeList:[],//活动类型列表
      chooseMedDialog: false,
      chooseQsDialog: false,
      formList2: [], //区分
      formList: [
        {
          executionTime: "",
          activityName: "",
          startDay: "",
          endDay: "",
          activityType: "", //活动类型
          activityDescription: "",
          activityStart: "", //第_天
          executionDay: "",
          activityFrequency: "", //每_天
          activityRemarks: "", //备注
          executionTime: "", //执行时间
          orderNum: "", //活动排序
          dialog: false,
          qsDialog: false, //qsDialog
          flag: false, //是否有主题内容
          show: true, //右侧展示
          leftShow: false, //左侧是否展示
          medicineList: [],
          missionaryWorks: [],
          indexLibraries: [],
          new: true,
          options: [1, 2, 3, 4, 5, 6, 7], //日期范围
          everyList: [],
        },
      ],
      dataRules: {
        activityName: [
          { required: true, message: "活动名不能为空", trigger: "blur" },
        ],
        activityType: [
          { required: true, message: "请选择活动类型", trigger: "change" },
        ],
        executionTime: [
          { required: true, message: "执行时间不能为空", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "活动排序不能为空", trigger: "blur" },
        ],
        activityRate: [
          { required: true, message: "请选择活动频率", trigger: "change" },
        ],
        activityStart: [
          { required: true, message: "时间不能为空", trigger: "blur" },
        ],
        activityFrequency: [
          { required: true, message: "时间不能为空", trigger: "blur" },
        ],
        executionDay: [
          { required: true, message: "时间不能为空", trigger: "blur" },
        ],
      },
      rateList: [], //频率列表
    };
  },
  mounted() {
    // console.log(this.programInfoId,this.entityId);
    // this.getTypeList()
    this.getRateList();
  },
  methods: {
    // 保存周期性方案活动信息
    async submit(index, mode) {
      console.log(this.formList[index]);
      let form = this.formList[index];
      let start = "";
      this.$refs.form[index].validate(async (valid) => {
        if (valid) {
          if (form.activityType === 1 && form.medicineList.length === 0) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          if (form.activityType === 4 && form.missionaryWorks.length === 0) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          if (
            (form.activityType === 5 || form.activityType === 6) &&
            form.indexLibraries.length === 0
          ) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          if (form.activityStart.length) {
            start = form.activityStart.join(",");
          }
          let obj = {
            programInfoId: this.programInfoId,
            entityId: this.entityId,
            activityName: form.activityName,
            activityType: form.activityType,
            activityMode: mode,
            activityStart: start,
            executionTime: form.executionTime,
            orderNum: form.orderNum,
            activityRemarks: form.activityRemarks,
            activityDescription: form.activityDescription,
            activityRate: form.activityRate,
            executionDay: form.executionDay,
            activityFrequency: form.activityFrequency,
            startDay: form.startDay,
            endDay: form.endDay,
            // id:form.id,
            // workUnitIds:'7b46ed791ec34b7aa078abf6f4ce4ad3'
          };
          // console.log(obj,'obj');
          if (form.medicineList && form.medicineList.length) {
            // obj.drugsManageId = form.medicineList[0].id
            obj.drugsManageId = form.medicineList[0].drugsManage.id;
            obj.drugsBaseDose = form.medicineList[0].drugsBaseDose;
            obj.drugsUseType = form.medicineList[0].drugsUseType;
            obj.drugsUseUnit = form.medicineList[0].drugsUseUnit;
          }
          console.log(form.missionaryWorks);
          if (form.missionaryWorks && form.missionaryWorks.length) {
            console.log("obj.missionaryWorkId", form.missionaryWorks[0][0].id);
            obj.missionaryWorkId = form.missionaryWorks[0][0].id;
          }
          if (form.indexLibraries && form.indexLibraries.length) {
            let arr = [];
            form.indexLibraries.forEach((item) => {
              arr.push(item.id);
            });
            obj.followUpId = arr.join(",");
          }
          if (form.id) {
            obj.id = form.id;
          }
          // console.log(Qs.stringify(obj),'完成');
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(
              obj.id ? "/programActivity/update" : "/programActivity/add"
            ),
            method: "post",
            data:obj,
            //  "Content-Type": "application/json;charset=UTF-8",
          });
          // console.log(res,'活动res');
          if (res.status) {
            this.$message.success(res.msg);

            // // this.$message.success('添加方案活动成功')
            // this.formList[index].show = false
            // this.formList[index].leftShow = true
            // // 添加完马上把id放回去 这样再次修改就是编辑
            // this.formList[index].id = res.data.id
            // // 预览方案则需要把数据传回父组件
            // console.log(res.data,'传数据之前');
            this.$emit("submit2", res.data);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    changeActivityType(index) {
      this.formList[index].flag = false;
    },
    // 新增活动
    addActivity() {
      //判断获取有无创建新的活动
      // let num = 0;
      // console.log(this.formList);
      // if (this.formList.length) {
      //   this.formList.forEach((ele) => {
      //     if (ele.new) {
      //       num++;
      //     }
      //   });
      // }
      // if (num > 0) {
      //   this.$message.error("请先保存本项活动。");
      //   return;
      // }
      let arr = this.formList.filter((chr) => {
        return chr.show;
      });
      if (arr.length == 1) {
        this.$message.error("请先保存本项活动");
      } else {
        this.formList.push({
          executionTime: "",
          activityName: "",
          activityType: "", //活动类型
          activityDescription: "",
          activityStart: "", //第_天
          activityRemarks: "", //备注
          executionTime: "", //执行时间
          executionDay: "",
          orderNum: "", //活动排序
          dialog: false,
          qsDialog: false, //qsDialog
          flag: false, //是否有主题内容
          show: true, //右侧展示
          leftShow: false, //左侧是否展示
          medicineList: [],
          missionaryWorks: [],
          indexLibraries: [],
          new: true,
        });
      }

      // console.log(this.$refs,'refs add');
    },
    // 删除活动
    async delActivity(card) {
      let id = card.id;
      this.$confirm("确定进行[删除活动]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          // this.dataListSelections.forEach(v=>{
          //   ids.push(v.id)
          // })
          // const id = ids.join(`,`)

          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/programActivity/delete?ids=${id}`),
            method: "post",
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除活动成功");
            // this.getDataList()
            // let arr = this.formList.filter((v)=> v.id!==card.id)
            // this.formList = arr
            this.$emit("submit2");
          }
        })
        .catch(() => {});
    },
    async getRateList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '频率',
		
		},
       
      });
      // console.log(res,'res')
      this.rateList = res.data;
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '方案活动类型',
		
		},
      
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    addMainCnt(form, index) {
      const type = form.activityType;
      if (type == 1) {
        form.dialog = true;
        // console.log(this.$refs,'refs click');
        this.$nextTick(() => {
          this.$refs.chooseMed[index].init();
        });
      } else if (type == 4 || type == 5 || type == 6) {
        form.qsDialog = true;
        //  let ref = `chooseQs${index}`
        this.$nextTick(() => {
          // this.$refs.ref.init();
          this.$refs.chooseQs[index].init();
        });
      }
    },
    getMed(val, index) {
      this.formList[index].flag = true;
      let yfArr = JSON.parse(sessionStorage.getItem("yfTypeArr"));
      // [val].forEach(v=>{
      yfArr.forEach((v2) => {
        if (val.drugsUseType == v2.value) {
          val.drugsUseTypeName = v2.name;
        }
      });
      // })
      this.formList[index].medicineList = [val];
    },
    getOther(val, index) {
      // console.log(val,'vallllllll');
      this.formList[index].flag = true;
      if (this.formList[index].activityType === 4) {
        this.formList[index].missionaryWorks = [val];

        // this.formList[index].missionaryWorks = [{workTypeName:val.type.name,workName:val.item.workName,id:val.item.id}]
      } else {
        if (val.length > 10) {
          this.$message.warning("最多添加10个问卷");
        } else {
          this.formList[index].indexLibraries = val;
        }

        // this.formList[index].indexLibraries = [{libraryTypeName:val.type.name,libraryName:val.item.libraryName,id:val.item.id}]
      }
    },
    deleteMed(row, index, delIndex) {
      this.formList[index].flag = false;
      if (this.formList[index].activityType === 1) {
        this.formList[index].medicineList = [];
      } else if (this.formList[index].activityType === 4) {
        this.formList[index].missionaryWorks = [];
      } else {
        let arr = JSON.parse(
          JSON.stringify(this.formList[index].indexLibraries)
        );

        arr.splice(delIndex, 1);
        console.log(arr);

        this.formList[index].indexLibraries = arr;
        this.formList[index].flag = true;
      }
    },
    cancelActivity(index) {
      // 新增的则删除 否则就隐藏
      if (this.formList[index].new && this.edit) {
        this.formList.splice(index, 1);
      } else {
        this.formList[index].show = false;
      }
    },
    showRight(index) {
      // this.formList[index].show = true
      let arr = this.formList.filter((chr) => {
        return chr.show;
      });
      if (arr.length == 1) {
        this.$message.error("请先保存本项活动");
      } else {
        this.formList.forEach((v, i) => {
          if (i == index) {
            v.show = true;
            // console.log(v, 1);
            this.changeRate(i, 1);
            this.changeOptions(i, 1);
            // v.activityStart = v.activityStart.split(',')
          } else {
            v.show = false;
          }
        });
      }
    },
    changeRate(index, flag) {
      // if(v==1){

      // }
      // else{
      console.log("changeRate");
      let v = this.formList[index].activityRate;
      // console.log(index,'index',this.formList[index],v);
      let arr = [];
      for (let i = 1; i < 31; i++) {
        arr.push({ value: i, name: i + this.rateList[v - 1].name });
      }
      // switch (v) {
      //   case 2:
      //     for (let i=1;i<8;i++){
      //       arr.push(i)
      //       // console.log(arr);
      //     }
      //     break;
      //   case 3:
      //     for (let i=1;i<31;i++){
      //       arr.push(i)
      //     }
      //     break;
      //   case 4:
      //     for (let i=1;i<91;i++){
      //       arr.push(i)
      //     }
      //     break;
      //   case 5:
      //     for (let i=1;i<366;i++){
      //       arr.push(i)
      //     }
      //     break;
      //   default:
      //     break;
      // }
      // console.log(arr,'arr');
      this.formList[index].everyList = arr;
      if (flag !== 1) {
        console.log("zhixing");
        this.formList[index].activityFrequency = "";
        this.formList[index].activityStart = [];
        this.formList[index].options = [];
      }
      // }
    },
    // sub(rate){
    //   let arr = []
    //     switch (v) {
    //       case 1:
    //         for (let i=1;i<8;i++){
    //           arr.push(i)
    //           // console.log(arr);
    //         }
    //         break;
    //       case 2:
    //         for (let i=1;i<8;i++){
    //           arr.push(i)
    //           // console.log(arr);
    //         }
    //         break;
    //       case 3:
    //         for (let i=1;i<31;i++){
    //           arr.push(i)
    //         }
    //         break;
    //       case 4:
    //         for (let i=1;i<91;i++){
    //           arr.push(i)
    //         }
    //         break;
    //       case 5:
    //         for (let i=1;i<366;i++){
    //           arr.push(i)
    //         }
    //         break;
    //       default:
    //         break;
    //     }
    // },
    changeOptions(index) {
      console.log("changeOptions");
      let v = this.formList[index].activityFrequency;
      let pre = "";
      if (v) pre = v;
      // else{ console.log('else',v)
      //   this.formList[index].activityStart =[]}
      let rate = this.rateList[this.formList[index].activityRate - 1].value;
      // rate 频率 pre 每

      console.log(pre, "pre", rate, "rate");
      if (rate == 1) this.$forceUpdate();
      else {
        // console.log(rate,'rate');
        let arr = [];
        switch (rate) {
          case 2:
            for (let i = 1; i <= 7 * pre; i++) {
              arr.push(i);
              // console.log(arr);
            }
            break;
          case 3:
            for (let i = 1; i <= 30 * pre; i++) {
              arr.push(i);
            }
            break;
          case 4:
            for (let i = 1; i <= 90 * pre; i++) {
              arr.push(i);
            }
            break;
          case 5:
            for (let i = 1; i <= 365 * pre; i++) {
              arr.push(i);
            }
            break;
          default:
            break;
        }
        // arr = arr.map(v=>{
        //   let obj = {}
        //   obj.name=v*rate
        //   obj.value=v*rate
        //   return obj
        // })
        console.log(arr, "arr");
        this.formList[index].options = arr;
        this.$forceUpdate();
      }
    },
  },
  watch: {
    circleList: function (value) {
      console.log(value, "123");
      value.forEach((v) => {
        console.log(v.activityStart);
        let arr = v.activityStart.split(",");
        arr = arr.map((v) => (v = Number(v)));
        console.log(arr, "arr");
        v.activityStart = arr;
      });
      this.formList = JSON.parse(JSON.stringify(value));
      console.log(this.formList, "formList watch");
      // this.formList.forEach((v,index)=>{
      //   // this.changeRate(index)
      //   // this.changeOptions(index)
      //   console.log(v);
      //   // console.log(v.activityStart,'activityStart');
      //   // if(v.activityStart){v.activityStart = v.activityStart.split(',')};
      // })
      this.formList2 = JSON.parse(JSON.stringify(value));
      // console.log(this.formList===value,'?');
    },
    // 'formList.activityRate':function(v){
    //   console.log(v,'vvvvv');
    // }
  },
};
</script>
<style lang="scss" scoped>
.circle {
  display: flex;
  .line {
    margin-top: 35px;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin-bottom: 20px;
  }
  .content {
    padding: 0 20px;
  }
  .lie {
    overflow: hidden;
    // display: flex;
    // justify-content: space-between;
    // margin-bottom: 20px;
    .el-form-item {
      float: left;
      width: 45%;
      margin-right: 10%;
      &:last-child {
        margin: 0;
      }
    }
    /deep/.el-form-item__label {
      width: 100px !important;
    }
    /deep/.el-form-item__content {
      margin-left: 100px !important;
    }
    .custom-form-item {
      display: flex;
      align-items: flex-start;
      /deep/.el-form-item__label {
        line-height: 1.2em;
      }
      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .main-cnt {
    padding: 0 10px;
    .title {
      display: flex;
      padding-bottom: 10px;
      .shu {
        width: 4px;
        height: 20px;
        background: #fbb933;
        border-radius: 5px;
      }
      .text {
        color: #fbb933;
        line-height: 20px;
        margin-left: 10px;
      }
    }
    .cnt-input {
      width: 100%;
      border-top: 1px dotted #4286f1;
      padding-top: 20px;
    }
    .other-type {
      width: 100%;
      height: 40px;
      border: 1px dotted #4286f1;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      color: #4286f1;
      cursor: pointer;
    }
  }
}
.left {
  margin-left: 5px;
  width: 15%;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  .card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 50px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 50px;
    cursor: pointer;
    margin-bottom: 20px;
    border: 1px solid #eee;
    border-radius: 5px;
    .text {
      width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .card:hover {
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
  }
}
.right {
  width: 80%;
}
.wd100 {
  width: 100px;
}
.wd200 {
  width: 200px;
}
.wd300 {
  width: 100%;
  /deep/.el-input__inner {
    width: 100% !important;
  }
}
.form {
  width: 100%;
  height: auto;
  border: 1px solid #eee;
  margin-top: 20px;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
    0 2px 4px 0 rgba(232, 237, 250, 0.5);
  // padding-bottom: 20px;
}
.form:first-child {
  margin-top: 0;
}
</style>
