<template>
  <div class="preview">
    <el-dialog title="方案预览" :visible.sync="visible" width="30%" :modal-append-to-body='false'>
      <el-timeline>
        <el-timeline-item v-for="(arr, index) in newList" :key="index">
          <!-- 多维数组不是单维的 -->
          <div v-for='(activity,index2) in arr' :key="index2">

            <div class="day" v-if='index2===0'>第{{activity.diff}}天</div>
            <div class="box-card">
            <div class="title">{{activity.title}}</div>
            <div class="time">计划时间：{{activity.executionTime}}</div>
            <!-- <el-divider></el-divider> -->
            <div class="mark" v-if='activity.activityRemarks'>备注：{{activity.activityRemarks}}</div>
            <div
              class="mark"
              v-if="activity.activityDescription"
            >描述：{{activity.activityDescription}}</div>
            <template v-else>
              <div class="fy" v-if="activity.activityType==1">
                <el-divider></el-divider>
                <div
                  class="title"
                  v-if="activity.medicineList"
                >用药：{{activity.medicineList[0].drugsFullName}}</div>
                <div
                  class="mark"
                  v-if="activity.medicineList"
                >用法：{{activity.medicineList[0].drugsUseTypeName}} &nbsp 用量：{{activity.medicineList[0].drugsUseUnit}} &nbsp 单位：{{activity.medicineList[0].drugsUseUnitName}}</div>
              </div>
              <div class="fy" v-else-if="activity.activityType==4">
                <el-divider></el-divider>
                <div
                  class="title"
                  v-if="activity.missionaryWorks"
                >宣讲类别：{{activity.missionaryWorks[0].workTypeName}}</div>
                <div
                  class="mark"
                  v-if="activity.missionaryWorks"
                >宣讲名称：{{activity.missionaryWorks[0].workName}} </div>
              </div>
              <div class="fy" v-else-if="activity.activityType==5">
                <el-divider></el-divider>
                <div
                  class="title"
                  v-if="activity.indexLibraries"
                >随访类别：{{activity.indexLibraries[0].libraryTypeName}}</div>
                <div
                  class="mark"
                  v-if="activity.indexLibraries"
                >随访名称：{{activity.indexLibraries[0].libraryName}} </div>
              </div>
            </template>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="visible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "preview",
  props: ["list",'executionDay'],
  data() {
    return {
      visible: false,
      now: "",
      list2: [],
      newList: [],
      // c:[],
      typeList: []
    };
  },
  mounted() {
    // console.log(moment());
    // this.getTypeList()
    this.now = moment().format("YYYY-MM-DD");
  },
  methods: {
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '方案活动类型',
		
		},
      
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    getType(num) {
      let type = this.typeList.find(v => {
        if (v.value == num) return v;
      });
      console.log(type, "name1111111111");
      return type.name;
    },
    init() {
      this.visible = true;
      console.log(this.executionDay);
      this.fix();
    },
    fix() {

      let _this = this;
      let list = this.list2
      let circle = [];
            let arr2= []

            let arr3= []
     console.log('list',list)
      let diff=1
      let once = list.filter(v => {
        if (v.drugsManageId) {
          v.medicineList = v.drugsManages;
        } else {
          v.medicineList = [];
        }
        v.title = `活动名称：${v.activityName}`;
        // v.startTime = moment(v.createTime)
        //   .add(v.activityStart, "days")
        //   .format("YYYY-MM-DD");
        if (v.activityMode == 2) {
          
          // console.log(v.executionDay, "executionDay");
          if(v.activityStart&&!Array.isArray(v.activityStart)&&v.activityRate!=1){
            // console.log(v.activityStart,'v.activityStart geshi');
            v.activityStart = v.activityStart.split(',')
            // console.log(typeof v.activityStart,'v.activityStart afther');

          }
          if(v.activityRate==1){
            for (var i = 0; i < this.executionDay; i++) {
              // v.diff = moment(v.startTime).add(i,'days').diff(moment(this.now),'days')
              // circle.push(v)
              // return
              // console.log(i,'i');
              // (function(index) {
                let obj = {};
                // console.log(index,'index');
                let diff;
                // if(v.activityRate==1){
  
                  diff = _this.getCircle(v,i)
                // }
                // console.log('get diff',diff);
                if(diff){
                  obj.diff = diff
                }
                // obj.diff = moment(v.startTime)
                //   .add(index, "days")
                //   .diff(moment(_this.now), "days");
                // console.log(index,'index',diff,'diff');
                obj.title = `活动名称：${v.activityName}`;
                obj.executionTime = v.executionTime;
                if (v.activityType == 1) {
                  obj.medicineList = v.medicineList;
                }else if(v.activityType === 4){
  
                  obj.missionaryWorks = v.missionaryWorks;
                }else if(v.activityType === 5){
  
                  obj.indexLibraries = v.indexLibraries;
                }
                
                obj.activityRemarks = v.activityRemarks;
                obj.activityDescription = v.activityDescription; //描述
                obj.activityTypeName = v.activityTypeName;
                obj.activityType = v.activityType 
                // obj.typeName=_this.getType(v.activityType)
                // console.log(obj,'obj');
                circle.push(obj);
              // })(i);
            }
          }else if(v.activityRate==2){
            
            let array = v.activityStart
                    console.log(array,'array');
                for(var i =0;i < this.executionDay; i++){
                    // (function(arr,allDays){
                      let obj = {}
                      obj.title = `活动名称：${v.activityName}`;
                      obj.executionTime = v.executionTime;
                      if (v.activityType == 1) {
                        obj.medicineList = v.medicineList;
                      }else if(v.activityType === 4){
                      
                        obj.missionaryWorks = v.missionaryWorks;
                      }else if(v.activityType === 5){
                      
                        obj.indexLibraries = v.indexLibraries;
                      }
                      
                      obj.activityRemarks = v.activityRemarks;
                      obj.activityDescription = v.activityDescription; //描述
                      obj.activityTypeName = v.activityTypeName;
                      obj.activityType = v.activityType 
                      let fw = v.activityFrequency*7
                      // console.log(allDays);
                        array.forEach(v=>{
                          if(i-v==0||(i-v)%fw==0){
                            obj.diff = i
                            arr2.push(obj)
                          }
                       })
                    // })(array,i)
                }
                console.log(arr2);
          }else if(v.activityRate==3){
            
            let array = v.activityStart
                    console.log(array,'array',this.executionDay);
                for(let i =0;i < this.executionDay; i++){
                      let obj = {}
                      obj.title = `活动名称：${v.activityName}`;
                      obj.executionTime = v.executionTime;
                      if (v.activityType == 1) {
                        obj.medicineList = v.medicineList;
                      }else if(v.activityType === 4){
  
                        obj.missionaryWorks = v.missionaryWorks;
                      }else if(v.activityType === 5){
  
                        obj.indexLibraries = v.indexLibraries;
                      }

                      obj.activityRemarks = v.activityRemarks;
                      obj.activityDescription = v.activityDescription; //描述
                      obj.activityTypeName = v.activityTypeName;
                      obj.activityType = v.activityType 
                      let fw = v.activityFrequency*30
                      // console.log(i,array,'arr');
                        array.forEach(v=>{
                          if(i-v==0||(i-v)%fw==0){
                            obj.diff = i
                            arr3.push(obj)
                          }
                       })
                    // })(array,i)
                }
                console.log(arr3);
          }
        } else {
          // v.diff = moment(v.startTime).diff(moment(this.now), "days");
          // console.log('once diff', v.diff );
          if(v.beforeActivityTime==0&&v.beforeActivityId==''){
              v.diff=1;
          }
          if(v.beforeActivityTime!==0&&v.beforeActivityId==''){
            v.diff = v.beforeActivityTime
          }
          // if(v.beforeActivityTime&&v.beforeActivityId){
          //   let before = 
          // }
          if (v.drugsManages && v.drugsManages.length) {
            v.medicineList = v.drugsManages;
          }
          return v;
        }
      });
      

      once = once.reverse()
      for(var j =0;j<once.length;j++){
        // console.log(once[j],'once[j]');
        once.forEach(v=>{
          if(v.id==once[j].beforeActivityId){
            // console.log(v,'v',once[j],'once[j]');
            // console.log(v.diff,'v diff',v.activityName,once[j].beforeActivityTime,once[j].activityName);
            once[j].diff=v.diff+once[j].beforeActivityTime
          }
        })
      }
            console.log(once);
      console.log(circle, "circle");
      console.log(arr2,arr3);
      let arr = [...once, ...circle,...arr2,...arr3].filter(v=>this.executionDay>=v.diff>0)
      console.log(arr);
      let newList =this.getTree(arr)
      this.newList = newList
      // diff 排序 目前diff还没重新梳理完
      this.newList.sort((a, b) => {
        return a[0].diff - b[0].diff;
      });
      // console.table(this.newList);
      // -------------------------------------one more--------------------------
      // let a  = list.filter(v=> {
      //   if(v.activityMode==1) {
      //     v.startTime = moment(v.createTime).add(v.activityStart,'days').format('YYYY-MM-DD')
      //     return v
      //   } })
      //   let b = list.filter(v=> {
      //   if(v.activityMode==2) {
      //     v.startTime = moment(v.createTime).add(v.activityStart,'days').format('YYYY-MM-DD')
      //     return v
      //   } })
      //   console.log(b);
      //   let c = []
      //   // var diff = ''

      //   b.forEach(v=>{
      //       for(var i=0;i<v.executionDay;i++){
      //       // v.diff = moment(v.startTime).add(i,'days').diff(moment(this.now),'days')
      //       // console.log(v.activityName,v.diff);
      //       // circle.push(v)
      //       // return

      //       (
      //         function(index){
      //           // 把diff挂载在v上面怎么都无法实现 永远是最后一个diff的值 重新定义一个obj后解决
      //           let obj = {}
      //           console.log(v,'vvvvvvvvvvvvvvv');
      //           let diff = moment(v.startTime).add(index,'days').diff(moment(_this.now),'days')
      //           console.log(index,'index',diff,'diff');
      //           // // console.log(diff,'mode2 diff');
      //           // v.diff = diff
      //           // alert(diff)
      //           // let obj = v
      //           // obj.diff = index
      //           // c.push(v)
      //           // ;(function(d){
      //           //   // console.log(value.diff);
      //           //   v.diff=d
      //           //   c.push(v)
      //           // })(diff)
      //           obj.diff=diff
      //           c.push(obj)
      //         }
      //       )(i)
      //     }
      //   })
      //   console.log(c);
      //   this.c = c
      // -------------------------------------one more--------------------------
    },
    getTree(arr){
      var map = new Map();
      var newArr = [];
      arr.forEach(item => {
           map.has(item.diff) ? map.get(item.diff).push(item) : map.set(item.diff, [item]);
      })
      newArr = [...map.values()];
      // console.table(newArr);
      return newArr
      // let newList = []
      // newList.push({
      //   children:[],
      //   diff:1
      // })
      // console.log(arr.length,'总个数');
      // arr.forEach(item=>{
      //   // console.log(item,'item');
      //   newList.forEach(v=>{
      //     console.log(newList.every((dep)=>{dep.diff!==item.diff}),'every');
      //     if(newList.every((dep)=>{dep.diff!==item.diff})){
      //       console.log(123123);
      //       // if(v.diff===item.diff){
      //       //   console.log(item.diff,'same');
      //       //   v.children.push(item)
      //       // }else{
      //       //   // newList.push({
      //       //   //   children:[],
      //       //   //   diff:item.diff
      //       //   // })
      //       //   // console.log(newList,'newList not same ');
      //       //   console.log(item.diff,'not same');
      //       // }
      //     }else{
      //       // newList.push({
      //       //     children:[],
      //       //     diff:item.diff
      //       //   })
      //     }
      //     // console.log(item,v,'getTree');
      //     //当新数组的每个元素的diff不等于原数组的diff时
      //     // let a =v.children.every(v2=>{
      //     //   console.log(v2);
      //     //   return
      //     // })
      //     //   console.log(a,'aaaa');
      //     // if(v.children.every((dep)=>{console.log(dep,item,'dep&item');dep.diff!==item.diff;})){

      //     // }
      //   })
      // })
      // console.log(newList);
      // for (var i = 0; i < arr.length; i++) {
      //   //最后一个不比较
      //   if(i===arr.length)return;
      //     if(arr[i].diff===arr[i+1].diff){
      //       newList.forEach(v=>{
      //         if(v.diff==arr[i].diff){
      //           v.children.push(arr[i],arr[i+1])
      //         }else{
      //           v.diff=arr[i].diff
      //           v.children = []
      //         }
      //       })
      //   }
      // }
    },
    getCircle(obj,i){
      let mei = obj.activityFrequency
      let di = obj.activityStart
      let rate = obj.activityRate
      let days=''
      // if(di.length) 
      // console.log(mei,di,rate);
      let diff = 0;
      switch (rate) {
        case 1:
          //每天
          if(i%mei==0){
            //能除尽
            diff=i
          }
          if(diff>0) return diff
          break;
        case 2:
          // console.log('case 2',di.length);
          //每周
          // if()
          
          break;
        default:
          break;
      }
      // if(rate==2){
      //     for(var j =0;j<di.length;j++){
      //       (function(index){
      //         // console.log(di[index],'indexxxxxx');
      //         diff=di[index]
      //         console.log(diff,'in fun');
      //               //  if(diff>0) return diff
      //       })(j)
      //       console.log(diff,'in for')
      //       if(diff>0) return diff
      //     }
      //       console.log(diff,'out')

      // }
      // console.log(diff,'diff circle');
    },
    

  },
  watch: {
    list:{
      handler(list){
      this.list2 = JSON.parse(JSON.stringify(list));
      console.log(this.list2,'watch');

      },
      immediate:true
    }
     
  }
};
</script>
<style lang="scss" scoped>
.preview {
  .box-card {
    cursor: pointer;
    padding: 10px;
    background-color: #f6f8fc;
    .title {
      color: #9ca6b8;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .time,
    .mark {
      color: #b8c0cd;
      line-height: 20px;
      font: 14px;
      // margin-top: 5px;
    }
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 10px 0;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
}
</style>